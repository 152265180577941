export const buildOptions = (dataGallery = [])=> {
    let agileOptions = {responsive:[]}
    dataGallery.forEach((item, index) => agileOptions.responsive = [...agileOptions.responsive, {
        breakpoint: index === 0 ? (dataGallery.length * 100) : (dataGallery.length * 100) - (index * 100),
        settings: {
            navButtons   : false,
            slidesToShow : dataGallery.length - index,
            dots         : true,
            unagile      : false,
            autoplay     : true,
        },
    }])
    return agileOptions
}
