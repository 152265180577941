<template>
  <div>
    <hero-global img="/img/what-is-cermi/what-is-cermi-1.svg" title="CERMI" subtitle="Qué es el CERMI" />
    <div class="container p-1-rem">
      <section class="menu-anclas container">
        <router-link :class="[this.$route?.params?.slug === 'que-es-cermi' ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']" :to="{name:'que-es-cermi', params:{ slug:'que-es-cermi' }}" @click="scrollSection('que-es-cermi')">Qué es el CERMI
        </router-link>
        <router-link :to="{name:'que-es-cermi', params:{ slug:'plataforma-unitaria-discapacidad' }}" @click="scrollSection('plataforma-unitaria-discapacidad')" :class="[this.$route?.params?.slug === 'plataforma-unitaria-discapacidad' ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']">
          Plataforma unitaria de la discapacidad
        </router-link>
        <router-link :to="{name:'que-es-cermi', params:{ slug:'mision-vision-valores' }}" @click="scrollSection('mision-vision-valores')" :class="[this.$route?.params?.slug === 'mision-vision-valores' ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']">
          Misión, visión y valores
        </router-link>
        <router-link :to="{name:'que-es-cermi', params:{ slug:'himno-cermi' }}" @click="scrollSection('himno-cermi')" :class="[this.$route?.params?.slug === 'himno-cermi' ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']">
          Himno del CERMI
        </router-link>
      </section>
      <div class="container">
        <select name="menu-anclas-responsive" id="menu-anclas-responsive" class="select-anclas-responsive " v-model="menuAnclas" :activeMenuTop="menuTopAnclas">
          <option value="que-es-cermi">
            Qué es el CERMI
          </option>
          <option value="plataforma-unitaria-discapacidad">
            Plataforma unitaria de la discapacidad
          </option>
          <option value="mision-vision-valores">
            Misión, visión y valores
          </option>
          <option value="himno-cermi">
            Himno del CERMI
          </option>

        </select>

      </div>
      <section class="position-relative p-2-rem container" id="que-es-cermi">
        <div class="text-center">
          <h2 class="title-grand title__double-center" data-text="Qué es el CERMI">Qué es el CERMI</h2>
        </div>
        <div class="grid-full">
          <div class="grid right">
            <ribbon text="Qué es el CERMI" />
            <div class="m-top-2-rem">
              <iframe class="d-block w-100" height="280" frameborder="0" src="https://www.youtube.com/embed/i56Ujwjuioc" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <article class="grid m-top-2-rem" v-html="$t('easyReader.que_es_cermi.text.'+this.store.easyRead.active)"></article>
        </div>
      </section>
    </div>
    <section id="plataforma-unitaria-discapacidad">
      <div class="text-center container">
        <h2 class="title-grand title__double-center" data-text="Plataforma unitaria de la discapacidad">Plataforma unitaria</h2>
      </div>
      <p class="subtitle text-center text-bold">Una actividad constante en favor de las personas con discapacidad</p>
      <p class="subtitle text-center text-bold">“El objetivo de CERMI es mejorar las condiciones de vida de las personas
        con discapacidad y sus familias”</p>
      <div class="container">
        <section class="p-1-rem">
          <div class="grid-full">
            <div class="grid right">
              <ribbon text="Plataforma unitaria de la discapacidad" />
              <div class="m-top-2-rem">
                <iframe class="d-block w-100" height="280" frameborder="0" src="https://www.youtube.com/embed/L0CLdK-nC7E" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <article class="grid m-top-2-rem" v-html="$t('easyReader.plataforma_unitaria.text.'+this.store.easyRead.active)"></article>
          </div>
        </section>
      </div>
      <div class="container">
        <h2 class="title-grand text-primary-light">Galería</h2>
        <div class="grid-4 justify-content-between carrousel">
          <agile :options="options" ref="carousel" class="carrousel-2 carrousel-home container">
            <div v-for="(data, index) in galleryData" href="" :key="index" :title="data.title">
              <img class="d-block w-18-rem" :src="data.img" :alt="data.img" />
            </div>
          </agile>
        </div>
      </div>
      <br />
      <br />
      <br />
    </section>
    <section class="container p-2-rem m-y-1-rem" id="mision-vision-valores">
      <div class="text-center container">
        <h2 class="title-grand title__double-center" data-text="Misión, visión y valores">Misión, visión y valores</h2>
      </div>
      <div class="container">
        <div class="grid-full">
          <div class="grid right">
            <ribbon text="Plataforma unitaria de la discapacidad" />
            <div class="m-top-2-rem">
              <iframe class="d-block w-100" height="315" frameborder="0" src="https://www.youtube.com/embed/_q-jk4SebUI" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <article class="grid m-top-2-rem" v-html="$t('easyReader.mision_vision_valores.text.'+this.store.easyRead.active)"></article>
        </div>
      </div>
    </section>
    <section class="container p-2-rem">
      <div class="grid-3 align-items-center justify-content-center w-100">
        <div class="grid m-0-px">
          <div class="card__column one">
            <div class="card__column-header">
              <h2 class="title-grand text-primary-light text-center">Misión</h2>
              <p class="font-family-roboto m-top-8 text-white font-light font-size-16">La misión del CERMI consiste en
                articular y vertebrar al movimiento social español de la discapacidad para garantizar los derechos y la
                inclusión, así como mejorar las condiciones de vida y ciudadanía de las personas con discapacidad y de
                sus
                familias.</p>
            </div>
            <div class="card__column-img" style="background-image: url(/img/what-is-cermi/what-is-cermi-7.svg)"></div>
          </div>
        </div>
        <div class="grid m-0-px">
          <div class="card__column two">
            <div class="card__column-header">
              <h2 class="title-grand text-primary-light text-center">Visión</h2>
              <p class="font-family-roboto m-top-8 text-white font-light font-size-16">El CERMI aspira a convertirse en
                el referente de la discapacidad globalmente considerada en España, encabezando la visión de derechos y
                la innovación social en materia de discapacidad, constituyéndose como motor de la transformación social
                en la defensa del reconocimiento de derechos de las personas con discapacidad y de sus familias y su
                plena inclusión en la comunidad.</p>
            </div>
            <div class="card__column-img" style="background-image: url(/img/what-is-cermi/what-is-cermi-8.svg)"></div>
          </div>
        </div>
        <div class="grid m-0-px">
          <div class="card__column three">
            <div class="card__column-header">
              <h2 class="title-grand text-primary-light text-center">Valores</h2>
              <p class="font-family-roboto m-top-8 text-white font-light font-size-16">Los valores que inspiran y orientan la actuación del CERMI son los que sustentan la Convención Internacional sobre los Derechos de los Personas con Discapacidad y además y en concreto los de unidad, cohesión interna, respeto a la diversidad, independencia y neutralidad políticas o ideológicas, igualdad entre mujeres y hombres, funcionamiento democrático, participación, transparencia, calidad, comportamiento ético y solidaridad social. </p>
            </div>
            <div class="card__column-img" style="background-image: url(/img/what-is-cermi/what-is-cermi-9.svg)"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="background-blue-3" id="objetivos-estrategicos">
      <div class="container">
        <div class="text-center ">
          <h2 class="title-grand title__double-center" data-text="Objetivos estratégicos del CERMI">Objetivos estratégicos</h2>
        </div>
        <p class="subtitle text-center">Para la llevar a la práctica la misión
          que tiene asignada, el CERMI se ha fijado como objetivos estratégicos</p>
      </div>
      <div class="p-2-rem container">
        <div class="grid-full">
          <div class="grid right ">
            <iframe class="d-block w-100" height="315" frameborder="0" src="https://www.youtube.com/embed/V_J075-Df60" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class=" grid-big accordion box-content ">
            <div class="content w-100">
              <ul class="list-item list">
                <li :aria-expanded="this.currentShow == 'aspace-1'">
                  <input @click="toggle('aspace-1')" type="checkbox" name="list" id="aspace-1"/>
                  <label for="aspace-1">
                    <span class="font-family-roboto font-size-16">1. Articulación y vertebración</span>
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-1'">
                    <p class="font-size-16 p-top-1-rem">Unidad y cohesión de todo el sector de la
                      discapacidad, con
                      respeto del pluralismo y la diversidad propia de este movimiento social. <b>El CERMI como
                        plataforma unitaria que aporta valor añadido al sector.</b>
                    </p>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-2'">
                  <input @click="toggle('aspace-2')" type="checkbox" name="list" id="aspace-2" />
                  <label for="aspace-2">
                    <span class="font-family-roboto font-size-16">2. Carácter representativo</span>
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-2'">
                    <p class="font-size-16 p-top-1-rem">El CERMI es exclusivamente una entidad de acción
                      política de carácter representativo, que no sustituye ni anula la labor propia de sus asociados,
                      no llevando a cabo por tanto actividades de prestación o gestión de servicios y programas con
                      incidencia directa entre las personas con discapacidad. <b>El CERMI como organización política
                        representativa</b>
                    </p>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-3'">
                  <input @click="toggle('aspace-3')" type="checkbox" name="list" id="aspace-3" />
                  <label for="aspace-3">
                    <span class="font-family-roboto font-size-16">3. Participación e implicación </span>
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-3'">
                    <p class="font-size-16 p-top-1-rem">Participación e implicación de las organizaciones
                      miembro, en pie de igualdad, en la elaboración y toma de decisiones y en los órganos de
                      representación y gobierno de la Entidad, aumentando la corresponsabilidad y el sentimiento de
                      pertenencia. <b>El CERMI como cosa propia de sus asociados.</b>
                    </p>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-4'">
                  <input @click="toggle('aspace-4')" type="checkbox" name="list" id="aspace-4" />
                  <label for="aspace-4">
                    <span class="font-family-roboto font-size-16">4. Independencia </span>
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-4'">
                    <p class="font-size-16 p-top-1-rem">El CERMI es una entidad sin adscripción política,
                      que actúa con plena autonomía e independencia tanto de los poderes públicos como de las
                      formaciones políticas, los grupos de presión, los agentes sociales y las empresas. Asimismo, el
                      CERMI actúa sumando, aunando y articulando coherentemente las distintas voluntades y
                      sensibilidades de sus organizaciones miembro, fruto del debate y el consenso internos, sin
                      someterse o dando preferencia a los intereses particulares o parciales de éstas. <b>El CERMI
                        como
                        entidad independiente hacia fuera y hacia dentro.</b></p>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-5'">
                  <input @click="toggle('aspace-5')" type="checkbox" name="list" id="aspace-5" />
                  <label for="aspace-5">
                    <span class="font-family-roboto font-size-16">5. Interlocutor válido del sector </span>
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-5'">
                    <p class="font-size-16 p-top-1-rem">Consolidación del CERMI como la entidad de
                      referencia de la discapacidad en nuestro país y el reconocimiento unánime por parte de los
                      poderes públicos y de la sociedad civil de su papel como interlocutor válido del movimiento
                      social de la discapacidad considerada globalmente, sin perjuicio de la interlocución que en su
                      ámbito propio corresponde a cada una de sus organizaciones miembro. <b>El CERMI como referente e
                        interlocutor válido de la discapacidad en España, sobre todo ante los Poderes Públicos.</b>
                    </p>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-6'">
                  <input @click="toggle('aspace-6')" type="checkbox" name="list" id="aspace-6" />
                  <label for="aspace-6">
                    <span class="font-family-roboto font-size-16">6. Utilidad para sus socios </span>
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-6'">
                    <p class="font-size-16 p-top-1-rem">Utilidad para sus socios y para el grupo de
                      población al que representa. El CERMI no tiene valor ni justificación por sí mismo, sino en
                      tanto su actividad de representación política unitaria del sector de la discapacidad revierte y
                      resulta útil en términos prácticos tanto a sus destinatarios finales (las personas con
                      discapacidad y sus familias) como a sus organizaciones miembro. <b>El CERMI como entidad
                        útil</b></p>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-7'">
                  <input @click="toggle('aspace-7')" type="checkbox" name="list" id="aspace-7" />
                  <label for="aspace-7">
                    <span class="font-family-roboto font-size-16">7. Proyección social e imagen pública </span>
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-7'">
                    <p class="font-size-16 p-top-1-rem">El progresivo cumplimiento de los anteriores
                      objetivos estratégicos tiene como presupuesto, en una sociedad mediática como en la que vivimos,
                      la acuñación de la marca "CERMI" que aumente la imágen pública y la proyección social de la
                      Entidad, reforzando de este modo su poder de interlocución y su capacidad de negociación. <b>El
                        CERMI como imagen de marca social de la discapacidad.</b></p>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-8'">
                  <input @click="toggle('aspace-8')" type="checkbox" name="list" id="aspace-8" />
                  <label for="aspace-8">
                    <span class="font-family-roboto font-size-16">8. Apertura a la sociedad </span>
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-8'">
                    <p class="font-size-16 p-top-1-rem">El CERMI en tanto que expresión organizada de la
                      articulación del sector de la discapacidad debe actuar como instrumento para llevar a la
                      sociedad la realidad de las personas con discapacidad y sus familias, fomentando el conocimiento
                      de este segmento de población, trasladando una imagen ajustada, que evite estereotipos y
                      prejuicios, y estimulando la toma de conciencia y la percepción social respecto de las personas
                      con discapacidad. <b>El CERMI como portavoz de la discapacidad.</b>
                    </p>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-9'">
                  <input @click="toggle('aspace-9')" type="checkbox" name="list" id="aspace-9" />
                  <label for="aspace-9">
                    <span class="font-family-roboto font-size-16">9. Acción en Europa y en la esfera internacional </span>
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-9'">
                    <p class="font-size-16 p-top-1-rem">La importancia decisiva que la construcción europea está
                      teniendo en todos los ámbitos, incluido el social, hace imprescindible prestar cada vez mayor
                      atención a la Unión Europea y a sus instituciones, participando activamente en los foros
                      representativos existentes, de forma que se pueda trasladar la sensibilidad del sector social de
                      la discapacidad español. Esto mismo para la esfera internacional en su conjunto, ámbito en el
                      que el CERMI desea a contribuir a crear un movimiento asociativo de la discapacidad. <b>El CERMI
                        como actor de la discapacidad española ante en Europa y en el ámbito internacional.</b></p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </section>
    <section class="container p-2-rem" id="himno-cermi">
      <div class="grid-2 align-items-center">
        <div class="grid">
          <iframe src="https://player.vimeo.com/video/129194089?h=4a75369dd2" class="d-block w-100" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="grid p-2-rem">
          <h3 class="text-bold text-primary font-size-24 title-decoration-bottom">Vídeo Corporativo CERMI</h3>
          <span></span>
          <br />
          <br />
          <p class="font-family-roboto font-light font-soze-16">En este vídeo, titulado “CERMI: Motor de cambio social”,
            se ofrece un breve relato audiovisual de presentación del origen, naturaleza, objeto social y trayectoria
            del CERMI. Para facilitar el acceso al contenido sin exclusiones, el vídeo dispone de subtitulado e
            interpretación en lengua de signos española de la locución.</p>
        </div>
      </div>
      <div class="grid-2 align-items-center">
        <div class="grid p-2-rem">
          <h3 class="text-bold text-primary font-size-24 title-decoration-bottom ">Himno del CERMI</h3>
          <br />
          <br />
          <!-- <p class="font-family-roboto font-light font-soze-16">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Ut ac consectetur erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis
            egestas. </p> -->
        </div>
        <div class="grid">
          <iframe src="https://player.vimeo.com/video/140029728?h=42f2df3e78" class="d-block w-100" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>

      <carrousel2 class="container m-bottom-40-px" />
    </section>
    <section class="container p-2-rem">
      <other-links-interest class="links-interest-box container m-bottom-5-rem" />
    </section>
  </div>
</template>

<script>
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import Ribbon from "@/components/Global/Ribbon";
  import Carrousel2 from "@/components/Global/Carrousel2";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import { VueAgile as agile } from "vue-agile";
  import { buildOptions } from "@/helpers/agileHelper";
  import { contentStore } from '@/stores/contents'
  export default {
    name: "WhatIsCermi",
    components: { OtherLinksInterest, Carrousel2, Ribbon, HeroGlobal, agile },
    setup() {
      const store = contentStore();
      store.loadProjects();
      return {
        store
      }
    },
    data: () => ({
      currentShow: null,
      menuAnclas: 'que-es-cermi',
      menuTopAnclas: false,
      galleryData: [
        {
          alt: "Galería CERMI",
          title: "Galería CERMI",
          img: "/img/what-is-cermi/what-is-cermi-1.jpg",
        },
        {
          alt: "Galería CERMI",
          title: "Galería CERMI",
          img: "/img/what-is-cermi/what-is-cermi-2.jpg",
        },
        {
          alt: "Galería CERMI",
          title: "Galería CERMI",
          img: "/img/what-is-cermi/what-is-cermi-3.jpg",
        },
        {
          alt: "Galería CERMI",
          title: "Galería CERMI",
          img: "/img/what-is-cermi/what-is-cermi-4.jpg",
        },
        {
          alt: "Galería CERMI",
          title: "Galería CERMI",
          img: "/img/what-is-cermi/what-is-cermi-5.jpg",
        },
        {
          alt: "Galería CERMI",
          title: "Galería CERMI",
          img: "/img/what-is-cermi/what-is-cermi-6.jpg",
        },
        {
          alt: "Galería CERMI",
          title: "Galería CERMI",
          img: "/img/what-is-cermi/what-is-cermi-7.jpg",
        },
        {
          alt: "Galería CERMI",
          title: "Galería CERMI",
          img: "/img/what-is-cermi/what-is-cermi-8.jpg",
        }
      ],
      options: {
        responsive: [{
          breakpoint: 900,
          settings: {
            navButtons: true,
            slidesToShow: 4,
            dots: false,
            centerMode: true,
            unagile: false,
          },
        }, {
          breakpoint: 800,
          settings: {
            navButtons: true,
            slidesToShow: 3,
            dots: false,
            unagile: false,
            autoplay: false,
          },
        },
        {
          breakpoint: 700,
          settings: {
            navButtons: true,
            slidesToShow: 2,
            dots: false,
            unagile: false,
            autoplay: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            navButtons: false,
            slidesToShow: 1,
            dots: true,
            unagile: false,
            autoplay: false,
          },
        },
        {
          breakpoint: 0,
          settings: {
            navButtons: false,
            slidesToShow: 1,
            dots: true,
            autoplay: false,
          },
        },],

      }
    }),
    mounted() {
      this.setSection()
      this.setAgileOptions()
      this.topMenuAnclas()
    },
    methods: {
      setAgileOptions: function () {
        this.agileOptions = buildOptions(this.galleryData)
      },
      topMenuAnclas: function () {
        window.onscroll = () => {
          if (window.scrollY > 0) {
            this.menuTopAnclas = true;
          } else {
            this.menuTopAnclas = false
          }
        }
      },
      setSection() {
        if (this.$route?.params?.slug) {
          let element = this.$route?.params?.slug
          element !== null && (() => {
            console.log(element)
            const offsetTop = document.getElementById(element)?.offsetTop - 150;
            setTimeout(function () {
              scroll({
                behavior: "smooth",
                top: offsetTop,
              });
            }, 200);
          })()
        }
      },
      toggle(id) {
        if (this.currentShow === id) {
          this.currentShow = null;
        } else {
          this.currentShow = id;
        }
      }
    },
    watch: {
      "$route"() {
        this.setSection()
      },
      "menuAnclas"() {
        let element = this.$route.hash.replace('#', '')
        const offsetTop = document.getElementById(this.menuAnclas).offsetTop - 150;
        setTimeout(function () {
          scroll({
            behavior: "smooth",
            top: offsetTop,
          });
        }, 200);
      }
    }
  }
</script>


<style scoped lang="scss">
  @import "@/styles/colours.scss";

  .carrousel {
    margin-top: -25px;

    .grid {
      width: 300px;
      height: 200px;
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 20px;
    }
  }

  .card__column {
    background-color: #0069B4;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    overflow: hidden;
    margin: auto;
    transition: .2s;
    justify-content: space-between;

    &:hover {
      transform: scale(1.05);
      transition: 0.3s;
      cursor: pointer;
    }

    &.one,
    &.three {
      max-width: 400px;
      height: 485px;
    }

    &.two {
      max-width: 400px;
      height: 550px;
    }

    .card__column-header {
      display: block;
      padding: 30px;
      height: 50%;
      z-index: 2;
    }

    .card__column-img {
      display: block;
      width: 100%;
      height: 30%;
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: cover;
      z-index: 1;
      bottom: 0;
    }
  }



  .box-content {
    background: transparent;

    .list-item.list>li {
      border-bottom: 1px solid $blueLight;
    }

    .inner-container {
      padding-left: 0 !important;

      ul {
        li {
          border-bottom: none;
        }
      }
    }

    label::before,
    .item-content.-one::before {
      bottom: -10px;
    }

  }

  #plataforma-unitaria-discapacidad,
  #objetivos-estrategicos {
    background: #8baddc26;
    padding: 40px 0;
    margin-bottom: 60px;

    h2 {
      color: #8baddc66;
    }
  }

  .carrousel-home {
    img {
      width: 95%;
      height: 190px;
      object-fit: cover;
      object-position: center center;
      border-radius: 30px;
    }
  }
</style>